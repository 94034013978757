@font-face {
  font-family: "brand-grey";
  src: url("brand-grey.eot");
  src: url("brand-grey.eot") format("embedded-opentype"), url("brand-grey.ttf") format("truetype"),
    url("brand-grey.woff") format("woff"), url("brand-grey.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "brand-red";
  src: url("brand-red.eot");
  src: url("brand-red.eot") format("embedded-opentype"), url("brand-red.ttf") format("truetype"),
    url("brand-red.woff") format("woff"), url("brand-red.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "white";
  src: url("white.eot");
  src: url("white.eot") format("embedded-opentype"), url("white.ttf") format("truetype"),
    url("white.woff") format("woff"), url("white.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "grey-60";
  src: url("grey-60.eot");
  src: url("grey-60.eot") format("embedded-opentype"), url("grey-60.ttf") format("truetype"),
    url("grey-60.woff") format("woff"), url("grey-60.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "icons";
  src: url("icons.eot");
  src: url("icons.eot") format("embedded-opentype"), url("icons.woff2") format("woff2"),
    url("icons.woff") format("woff"), url("icons.ttf") format("truetype"), url("icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

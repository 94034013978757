@import "colors";

.pull-to-refresh-off {
  overscroll-behavior: none;
}

body {
  margin: 0;

  .text-field-label {
    white-space: nowrap;
  }

  .mat-mdc-menu-panel {
    padding: 10px;

    .mat-mdc-menu-item {
      display: flex;
      height: 56px;
      align-items: center;
      justify-content: flex-start;

      .mat-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
      }
    }

    .mat-mdc-menu-content {
      &:not(:empty) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  .mat-icon {
    display: flex;
    width: unset;
    height: unset;
  }

  .mat-mdc-mini-fab {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mat-expansion-panel-header {
    height: 3rem;

    &:focus {
      border: unset !important; // sass-lint:disable-line no-important
      background: inherit !important; // sass-lint:disable-line no-important
    }
  }

  .mat-accordion {
    .mat-expansion-panel {
      width: unset;
    }
  }

  .mat-mdc-card {
    width: unset;
    box-shadow: // sass-lint:disable indentation no-color-literals
      0 2px 1px -1px rgb(0 0 0 / 20%),
      0 1px 1px 0 rgb(0 0 0 / 14%),
      0 1px 3px 0 rgb(0 0 0 / 12%) !important; // sass-lint:disable-line no-important
  }

  .mat-mdc-tab-nav-bar {
    &.mat-primary {
      .mat-ink-bar {
        background-color: $color-brand-red;
      }
    }
  }

  .mat-mdc-dialog-container {
    min-width: 21.5rem;
    overscroll-behavior: contain;

    .mat-mdc-dialog-content {
      margin: 0;
      padding: 0;
      overflow-y: unset;
    }

    .mat-mdc-dialog-actions {
      margin-bottom: 0;
      gap: 0.5rem;
    }
  }

  .mat-mdc-input-element {
    color: var(--primary-darker-color);
  }

  .time-picker-toggle .ngx-material-timepicker-toggle,
  .mat-mdc-icon-button.mat-mdc-button-base {
    color: var(--primary-color);
  }

  .mat-mdc-form-field .mat-mdc-form-field-icon-suffix {
    color: var(--primary-color);
  }

  .mdc-line-ripple--active {
    opacity: 0;
  }

  mat-icon,
  mat-icon.mat-primary {
    color: var(--primary-color);

    &.mat-icon-no-color {
      color: unset;
    }
  }

  .mat-badge-content {
    background-color: var(--primary-color);
  }

  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  .mat-button-toggle-group-appearance-standard {
    border: none;
  }

  .mat-mdc-slider.mat-primary {
    --mdc-slider-handle-color: #{$color-brand-red};
    --mdc-slider-focus-handle-color: #{$color-brand-red};
    --mdc-slider-hover-handle-color: #{$color-brand-red};
    --mdc-slider-active-track-color: #{$color-brand-red};
    --mdc-slider-inactive-track-color: #{$color-grey-80};
  }

  .mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-elevated-container-color: #{$color-grey-60};
    --mdc-chip-with-trailing-icon-trailing-icon-color: #{$color-grey-40};
  }

  .mat-mdc-button-base.mat-mdc-button:not(a[href]):hover {
    color: #{$color-brand-red};
  }

  .mat-mdc-tab-group {
    touch-action: auto !important;
  }

  .mat-mdc-option {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
